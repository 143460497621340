const Images = {    
     KOL: require('../assets/img/Kolkata.png'),
     HYD: require('../assets/img/Hyderabad.png'),
     NODATA: require('../assets/img/nodata.png'),
     BELL: require('../assets/img/bell.png'),     
     EYE: require('../assets/img/preview.png'),     
     DROPDOWN: require('../assets/img/dropdown.png'),
     DROPDOWNDARK: require('../assets/img/dropdarkdown.png'),
     PIN: require('../assets/img/pin.png'),     
     Pinpink: require('../assets/img/pinpink.png'),
     INFO: require('../assets/img/info.png'),
     CENCLEBTN: require('../assets/img/cancel-icon.svg'),
     ADDBTN: require('../assets/img/add-icon.svg'),
     COINIMG: require('../assets/img/coin-ic.png'),
     PROMOTE: require('../assets/img/promote.png'),
     PIN_ACTIVE: require('../assets/img/pin-active.png'),
     SALARY_REVIEW_BAR: require('../assets/img/salary-review-bar.png'),
     CHOOSE_TEMPLATE_BAR: require('../assets/img/choose-template.png'),
     dummy_user: require('../assets/img/avatar.jpg'),   
     no_image: require('../assets/img/no_image.png'),     
     VERIFIED: require('../assets/img/verified.png'),
     DEFAULT_CIRCLE: require('../assets/img/default_circle.png'),
     FLAG_ENABLE: require('../assets/img/flag_enable.png'),     
     REFER_FRD: require('../assets/img/refer_a_frd.png'),
     DAILY_STREAK: require('../assets/img/daily_streak.png'),    
     PROMOTION: require('../assets/img/promotion.png'),
     ERROR_ICON: require('../assets/img/error-icon.svg'),
     REWARD_ICON: require('../assets/img/reward-coin.svg'),
     FEEDBACK: require('../assets/img/feedback.png'),
     CARD: require('../assets/img/card.png'),
     BANK_DOCUMENT: require('../assets/img/bank_document.png'),     
     CHECKED: require('../assets/img/checked.png'),
     PREDICTION_1: require('../assets/img/prediction1.png'), 
     OP_PREDICTION_1: require('../assets/img/op1.png'), 
     OP_PREDICTION_2: require('../assets/img/op2.png'), 
     OP_PREDICTION_3: require('../assets/img/op3.png'), 
     DEF_ADDPHOTO: require('../assets/img/addphoto.png'), 
     DRAW_IMG: require('../assets/img/draw-img.png'), 
     NO_AFFILIATE: require('../assets/img/no_affiliate.png'),
     REVERSE_FANTASY: require('../assets/img/reverse_fantasy.png'),
     COINS_IMG: require('../assets/img/coins-img.png'),
     INSTANT_PENDING: require('../assets/img/instant_pending.png'),
     INSTANT_APPROVAL: require('../assets/img/instant_approval.png'),
     INSTANT_REJECT: require('../assets/img/instant_reject.png'),
     PENDING: require('../assets/img/pending.png'),
     APPROVAL: require('../assets/img/approval.png'),
     REJECT: require('../assets/img/reject.png'),
}
export default Images;