import config from '../config';
const dotenv = require("dotenv"); // Used to set .env variable to node process env
dotenv.config({ path: '../.env' });
setTimeout(() => {
    console.log('DOT ENV BASE_URL', process.env.REACT_APP_BASE_URL)
    console.log('DOT ENV REACT_APP_S3URL', process.env.REACT_APP_S3URL)
}, 2000);


//Upload banner path
var S3_SERVER_URL = process.env.REACT_APP_S3URL;
var S3_REWARD_URL = process.env.REACT_APP_S3URL;
export const ADMIN_FOLDER_NAME = 'admin/index.php/';
export const ADMIN_AUTH_KEY = 'admin_id_token';
//baseUrl coming from admin env file 
export const baseURL = config.apiGateway.URL;
//export const baseURL = process.env.REACT_APP_BASE_URL_API;
export const ALLOW_COMMUNICATION_DASHBOARD = 1;
export const ALLOW_DEAL = 1;
export const ALLOW_DFS = 1;
export const ALLOW_FREETOPLAY = process.env.REACT_APP_ALLOW_FREETOPLAY
export const ALLOW_OP_PREDICTION = 1;
export const ALLOW_OP_WITH_POOL = process.env.REACT_APP_ALLOW_OP_WITH_POOL;
export const ALLOW_SPINTHEWHEEL = process.env.REACT_APP_ALLOW_SPINTHEWHEEL;
export const ALLOW_BUY_COIN = process.env.REACT_APP_ALLOW_BUY_COIN;
export const ALLOW_REFERRAL_SETPRIZE = process.env.REACT_APP_ALLOW_REFERRAL_LEADERBOARD;
export const SHOW_PREDICTION_CHILD = localStorage.getItem('ALLOW_PREDICTION_MODULE');
export const SHOW_OP_PREDICTION_CHILD = localStorage.getItem('ALLOW_OPEN_PREDICTOR');
export const deviceType = "3";
export const deviceID = "";
export const leagueId = "";
export const sportsId = "5";
export const CURRENCY = "₹";
export const successCode = 200;
export const AUTHENTICATE_REQUIRE_CODE = 401;
export const sessionExpireCode = 401;
export const ITEMS_PERPAGE = 50;
export const ITEMS_PERPAGE_XS = 10;
export const ITEMS_PERPAGE_LG = 100;
export const CURRENT_PAGE = 1;
export const EXPORT_REPORT_LIMIT = 10000;
export const sessionKey = "";
export const SYSTEM_ERROR = "System generated an error please try again later.";
// export const BANNER_ERROR = "Please upload image of size should be 340x60.";
export const NO_RECORDS = "No Records Found.";
// export const NO_PARTICIPANTS = "No participants till now";
export var Locale = "";
export class Language {
    static update(data) {
        Locale = data;
    }
}
//Imnages folder dir path
export const S3_REWARD = S3_REWARD_URL;
//Imnages folder dir path
export const S3 = S3_SERVER_URL;
export const UPLOAD = 'upload/';
export const BANNER = 'upload/banner/';
export const APPBANNER = 'upload/app_banner/';
export const FLAG = 'upload/flag/';
export const JERSY = 'upload/jersey/';
export const MERCHANDISEIMG = 'upload/merchandise/';
export const SPONSER_IMG_PATH = 'upload/sponsor/';
export const SETTING_IMG_PATH = 'upload/setting/';

export const PAN = 'upload/pan/';
export const THUMB = 'upload/profile/thumb/';
export const BANK = 'upload/bank_document/';
export const BANK_IMG = 'upload/bank/';
export const COINS = 'upload/rewards/';
export const NOIMAGE = 'assets/img/no_image.png';
export const OP_CATEGORY = 'upload/category/';
export const OP_PROOF = 'upload/open_predictor/';
export const FX_SPONSOR_LOGO = 'upload/fixed_open_predictor/sponsor/';
export const GROUP_ICON = 'assets/img/';
export const PUSH_HEADER = 'upload/cd/push_header/';
export const PUSH_BODY = 'upload/cd/push_body/';

//Datepicker
export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_DIFF = 15;


//Prediction share Url
export const PredictionShareUrl = "cricket/open-predictor-details/";
export const FixedPredictionShareUrl = "cricket/open-predictor-leaderboard-details/";
export const PickemShareUrl = "/pickem-detail/";

export const GET_ALL_SPORTS = "common/get_all_sport";
export const GET_SPORT_LEAGUES = "admin/common/get_sport_leagues";

//Team Module 
export const GET_ALL_TEAM_LIST = "admin/index.php/team/get_all_team_by_sport";
export const UPLOAD_TEAM_JERSEY = "admin/index.php/team/do_upload/jersey";
export const UPLOAD_TEAM_FLAG = "admin/index.php/team/do_upload/flag";
export const EDIT_TEAM_INFO = "admin/index.php/team/edit_team_details";

//League Module 
export const GET_ALL_LEAGUE_LIST = "admin/index.php/league/get_sport_leagues";
export const GET_ALL_FIXTURE_LIST = "admin/index.php/multigame/get_published_fixtures";
export const CREATE_COLLECTION = "admin/index.php/multigame/create_collection";
export const GET_ALL_COLLECTION_FIXTURE_LIST = "admin/index.php/multigame/get_all_collection_season_schedule";
export const GET_COLLECTION_CONTEST_LIST = "admin/index.php/multigame/get_all_collection_contests";
export const GET_COLLECTION_SEASON_DETAILS = "admin/index.php/multigame/get_season_details";
export const GET_COLLECTION_FIXTURE_CONTEST = "admin/index.php/multigame/get_fixture_contest";
//multigame
export const GET_MULTIGAME_CONTEST_LIST = "admin/index.php/multigame/multigame/get_contest_list";
export const GET_COLLECTION_FIXTURE_TEMPLATE = "admin/index.php/multigame/get_fixture_template";
export const CREATE_TEMPLATE_COLLECTION_CONTEST = "admin/index.php/multigame/create_template_contest";
export const COLLECTION_CREATE_CONTEST = "admin/index.php/multigame/create_contest";
//Fixture Module
export const GET_ALL_FIXTURE = "admin/index.php/season/get_all_season_schedule";
export const GET_SEASON_TEAMS_AND_ROSTERS = "admin/index.php/season/get_season_teams_and_rosters";
export const UPDATE_SEASON_PLAYER_SALARY = "admin/index.php/season/update_season_roster_salary";
export const PUBLISH_SEASON = "admin/index.php/season/publish_season";
export const GET_SEASON_DETAILS = "admin/index.php/season/get_season_details";

//Dashboard Module
export const GET_DASHBOARD_SUMMARY = "admin/index.php/dashboard/get_summary";
export const GET_DASHBOARD_TIMELINES = "admin/index.php/dashboard/get_timelines";
export const GET_DASHBOARD_FREEPAID_USERS = "admin/index.php/dashboard/get_freepaidusers";
export const GET_DASHBOARD_DEVICES = "admin/index.php/dashboard/get_devices";
export const GET_DASHBOARD_SITERAKE = "admin/index.php/dashboard/get_siterake";
export const GET_DASHBOARD_LEADERBOARD = "admin/index.php/dashboard/get_leaderboard";
export const GET_DASHBOARD_REFERRAL = "admin/index.php/dashboard/get_referral";
export const GET_CALCULATED_SUMMARY = "admin/index.php/dashboard/get_calculated_summary";
export const GET_DASHBOARD_SEGREGATION = "admin/index.php/dashboard/get_segregation";
export const GET_DASHBOARD_ACTIVE_USERS = "admin/index.php/dashboard/get_active_users";

//contest template
export const GET_CONTEST_TEMPLATE_MASTER_DATA = "admin/index.php/contest/contest_template/get_all_master_data";
export const GET_CONTEST_TEMPLATE_LIST = "admin/index.php/contest/contest_template/get_contest_template_list";
export const CREATE_CONTEST_TEMPLATE = "admin/index.php/contest/contest_template/create_template";
export const DELETE_CONTEST_TEMPLATE = "admin/index.php/contest/contest_template/delete_template";
export const APPLY_CONTEST_TEMPLATE_TO_LEAGUE = "admin/index.php/contest/contest_template/apply_template_to_league";
export const GET_FIXTURE_TEMPLATE = "admin/index.php/contest/contest_template/get_fixture_template";

//contest section
export const CREATE_TEMPLATE_CONTEST = "admin/index.php/contest/contest/create_template_contest";
export const GET_FIXTURE_CONTEST = "admin/index.php/contest/contest/get_fixture_contest";
export const CREATE_CONTEST = "admin/index.php/contest/contest/create_contest";
export const MARK_PIN_CONTEST = "admin/index.php/contest/contest/mark_pin_contest";
export const GET_CONTEST_FILTER = "admin/index.php/sl_contest/sl_contest/get_contest_filter";
export const GET_CONTEST_LIST = "admin/index.php/sl_contest/sl_contest/get_contest_list";
export const DELETE_CONTEST = "admin/index.php/contest/contest/delete_contest";
export const REMOVE_CONTEST_RECURRENCE = "admin/index.php/contest/contest/remove_contest_recurrence";
export const UPLOAD_SPONSER = "admin/index.php/contest/contest/do_upload_sponsor";
export const UPLOAD_CONTEST_TEMPLATE_SPONSER = "admin/index.php/contest/contest_template/do_upload_sponsor";

//user module 
export const GET_USERLIST = "admin/index.php/user/users";
export const CHANGE_USER_STATUS = "admin/index.php/user/change_user_status";
export const GET_USER_DETAIL = "admin/index.php/user/get_user_detail";
export const GET_USER_BASIC = "admin/index.php/user/get_user_basic";
export const VERIFY_USER_PANCARD = "admin/index.php/user/verify_user_pancard";
export const GET_USER_BANK_DATA = "admin/index.php/user/get_user_bank_data";
export const UPDATE_WALLET = "admin/index.php/user/add_user_balance";
export const GET_USER_TRANSACTION_HISTORY = "admin/index.php/user/user_transaction_history";
export const ADD_USER = "admin/index.php/user/add_user";

//login 
export const DO_LOGIN = "admin/index.php/auth/dologin";

//Scoring
export const GET_SCORING_FILTERS = 'admin/index.php/scoring/get_scoring_filters'
export const GET_SCORING_RULES = 'admin/index.php/scoring/get_scoring_rules'
export const UPDATE_MASTER_SCORING_POINTS = 'admin/index.php/scoring/update_master_scoring_points'

//CMS
export const GET_BANNERS = 'admin/index.php/banner/get_banners';
export const GET_BANNER_TYPE = 'admin/index.php/banner/get_banner_type';
export const CREATE_BANNER = 'admin/index.php/banner/create_banner';
export const GET_APP_BANNERS = 'admin/index.php/app_banner/get_app_banners';
export const ADD_APP_BANNER = 'admin/index.php/app_banner/add_app_banner';
export const UPDATE_STATUS = 'admin/index.php/app_banner/update_status';
export const DELETE_APP_BANNER = 'admin/index.php/app_banner/delete_app_banner';
export const GET_PAGES = 'admin/page/pages';
export const GET_PAGE_DETAIL = 'admin/page/get_page_detail';
export const UPDATE_PAGE = 'admin/page/update_page';
export const FRONT_BG_UPLOAD = 'admin/index.php/setting/front_bg_upload';
export const RESET_FRONT_BG_IMAGE = 'admin/index.php/setting/reset_front_bg_image';
export const GET_FRONT_BG_IMAGE = 'admin/index.php/setting/get_front_bg_image';
export const GET_ALL_UPCOMING_COLLECTIONS = 'admin/index.php/contest/get_all_upcoming_collections';
export const LOBBY_IMAGE_UPLOAD = 'admin/index.php/banner/do_upload';
export const DELETE_LOBBY_BANNER = 'admin/index.php/banner/delete_banner';
export const LOBBY_UPDATE_STATUS = 'admin/index.php/banner/update_status';
export const APP_BANNER_UPLOAD = 'admin/index.php/app_banner/do_upload';

//User dashboard
export const GET_USER_NOSQL_DATA = 'admin/index.php/user/get_user_nosql_data';

//User dashboard
export const GET_USER_GAME_HISTORY = 'admin/index.php/user/user_game_history/';
export const GET_USER_REFERRAL_DATA = 'admin/index.php/user/get_user_referral_data';
export const GET_LINEUP_DETAIL = 'admin/index.php/user/get_lineup_detail';
export const GET_GAME_STATS = 'admin/sl_contest/get_user_joined_contests';

//Marketing Referral
export const GET_AFFILIATE_MASTER_DATA = 'admin/index.php/setting/get_affiliate_master_data';
export const UPDATE_AFFILIATE_MASTER_DATA = 'admin/index.php/setting/update_affiliate_master_data';
export const GET_PROMO_CODES = 'admin/index.php/promo_code/get_promo_codes';
export const NEW_PROMO_CODE = 'admin/index.php/promo_code/new_promo_code';
export const CHANGE_PROMO_STATUS = 'admin/index.php/promo_code/change_promo_status';
export const GET_PROMO_CODE_DETAIL = 'admin/index.php/promo_code/get_promo_code_detail';
export const DELETE_PROMO_CODE = 'admin/index.php/promo_code/delete_promo_code';

//Finance data
export const GET_WITHDRAWAL_FILTER_DATA = 'admin/index.php/finance/get_filter_data';
export const GET_ALL_WITHDRAWAL_REQUEST = 'admin/index.php/finance/get_all_withdrawal_request';
export const CHANGE_WITHDRAWAL_STATUS = 'admin/index.php/finance/change_withdrawal_status';
export const GET_ALL_TRANSACTION = 'admin/index.php/finance/get_all_transaction';
export const SEND_EMAIL_SELECTED_USER = 'admin/index.php/user/send_email_selected_user/';
export const GET_GAME_DETAIL = 'admin/index.php/contest/get_game_detail';
export const GET_GAME_LINEUP_DETAIL = 'admin/index.php/contest/get_game_lineup_detail';

export const ADD_NOTE = 'admin/index.php/user/add_note';
export const GET_NOTES = 'admin/index.php/user/get_notes';
export const VERIFY_BANK = 'admin/index.php/user/verify_user_bank';
//Report

export const GET_ALL_USER_REPORT = 'admin/index.php/report/get_all_user_report';
export const GET_REPORT_MONEY_PAID_BY_USER = 'admin/index.php/report/get_report_money_paid_by_user';
export const REFERAL_REPORT = 'admin/index.php/report/referal_report';
export const GET_DEPOSIT_AMOUNT_FILTER_DATA = 'admin/index.php/report/get_deposit_amount_filter_data';
export const GET_REPORT_USER_DEPOSIT_AMOUNT = 'admin/index.php/report/get_report_user_deposit_amount';
export const GET_ALL_CONTEST_REPORT = 'admin/index.php/report/get_all_contest_report';
export const GET_SPORT_LEAGUES_REPORT = "admin/index.php/contest/get_sport_leagues";
export const GET_ALL_COLLECTIONS_BY_LEAGUE = "admin/index.php/contest/get_all_collections_by_league";
export const GET_SYSTEM_USER_REPORTS = "admin/systemuser/get_system_user_reports";
export const GET_SYSTEM_USER_LEAGUE_LIST = "admin/systemuser/get_system_user_league_list";

export const GET_LANGUAGE_LIST = "admin/index.php/common/common/get_language_list";
export const DO_UPLOAD_LANG = "admin/index.php/common/common/do_upload_lang/";
export const DO_UPLOAD_MASTER_FILE = "admin/index.php/common/common/do_upload_master_file/";
export const GET_ALL_POSITION = "admin/index.php/roster/get_all_position";
export const PLAYING11 = "admin/index.php/roster/select_playing11";

//Coin module
export const GET_COIN_CONFIGURATION_DETAILS = "admin/index.php/coins/get_coin_configuration_details";
export const UPDATE_COINS_STATUS = "admin/index.php/coins/update_coins_status";
export const SAVE_COINS_CONFIGURATION = "admin/index.php/coins/save_coins_configuration";
export const GET_REWARD_LIST_BY_STATUS = "admin/index.php/coins/get_reward_list_by_status";
export const APPROVE_REWARD_REQUEST = "admin/index.php/coins/approve_reward_request";
export const GET_REWARD_LIST = "admin/index.php/coins/get_reward_list";
export const ADD_REWARD = "admin/index.php/coins/add_reward";
export const DO_UPLOAD_REWARD_IMAGE = "admin/index.php/coins/do_upload_reward_image";
export const UPDATE_REWARD_STATUS = "admin/index.php/coins/update_reward_status";
export const GET_REWARD_HISTORY = "admin/index.php/coins/get_reward_history";
export const EXPORT_REWARD_LIST_BY_STATUS = "admin/index.php/coins/export_reward_list_by_status";
export const GET_TOP_EARNER = "admin/index.php/coins/get_top_earner";
export const GET_TOP_REDEEMER = "admin/index.php/coins/get_top_redeemer";
//Feedback module
export const ADD_FEEDBACK_QUESTION = "admin/index.php/promotions/feedback/add_feedback_question";
export const GET_FEEDBACK_QUESTIONS_BY_STATUS = "admin/index.php/promotions/feedback/get_feedback_questions_by_status";
export const UPDATE_FEEDBACK_QUESTION_STATUS = "admin/index.php/promotions/feedback/update_feedback_question_status";
export const UPDATE_FEEDBACK_QUESTION = "admin/index.php/promotions/feedback/update_feedback_question";
export const GET_FEEDBACK_QUESTION_DETAILS = "admin/index.php/promotions/feedback/get_feedback_question_details";
export const COMMENTS_FOR_FEEDBACK_QUESTIONS_BY_STATUS = "admin/index.php/promotions/feedback/comments_for_feedback_questions_by_status";
export const GET_FEEDBACKS_BY_STATUS = "admin/index.php/promotions/feedback/get_feedbacks_by_status";
export const RATE_FEEDBACK = "admin/index.php/promotions/feedback/rate_feedback";
export const UPDATE_FEEDBACK_STATUS = "admin/index.php/promotions/feedback/update_feedback_status";
export const GET_COIN_DISTRIBUTED_HISTORY = "admin/index.php/coins/get_coin_distributed_history";
export const EXPORT_COIN_DISTRIBUTION_HISTORY = "admin/index.php/coins/export_coin_distribution_history";
export const EXPORT_TOP_EARNER = "admin/index.php/coins/export_top_earner";
export const EXPORT_TOP_REDEEMER = "admin/index.php/coins/export_top_redeemer";
export const COIN_REDEEM_HISTORY = "admin/index.php/coins/coin_redeem_history";
export const EXPORT_COIN_REDEEM_HISTORY = "admin/index.php/coins/export_coin_redeem_history";
export const COIN_DISTRIBUTED_GRAPH = "admin/index.php/coins/coin_distributed_graph";
export const COIN_REDEEM_GRAPH = "admin/index.php/coins/coin_redeem_graph";
export const USER_COIN_REDEEM_GRAPH = "admin/index.php/coins/user_coin_redeem_graph";
export const GET_PENDING_COUNTS = "admin/index.php/user/get_pending_counts";
//FreeToPlay Module
export const UPLOAD_MERCHANDISE_IMG = "admin/sl_contest/do_upload";
export const ADD_MERCHANDISE = "admin/sl_contest/save_merchandise";
export const GET_MERCHANDISE_LIST = "admin/sl_contest/get_merchandise";
export const REMOVE_MERCHANDISE = "admin/index.php/merchandise/remove_merchandise_image";
export const UPDATE_MERCHANDISE = "admin/index.php/merchandise/update_merchandise";

//Prediction module
export const GET_PREDICTION_STATUS = "admin/prediction/get_prediction_status";
export const UPDATE_PREDICTION_STATUS = "admin/prediction/update_prediction_status";
export const GET_SEASON_LIST = "admin/prediction/get_season_list";
export const CREATE_PREDICTION = "admin/prediction/create_prediction";
export const UPDATE_PIN_PREDICTION = "admin/prediction/update_pin_prediction";
export const PAUSE_PLAY_PREDICTION = "admin/prediction/pause_play_prediction";
export const GET_ALL_PREDICTION = "admin/prediction/get_all_prediction";
export const GET_PREDICTION_COUNTS = "admin/prediction/get_prediction_counts";
export const GET_TRENDING_PREDICTIONS = "admin/prediction/get_trending_predictions";
export const GET_PREDICTION_PARTICIPANTS = "admin/prediction/get_prediction_participants";
export const SUBMIT_PREDICTION_ANSWER = "admin/prediction/submit_prediction_answer";
export const DELETE_PREDICTION = "admin/prediction/delete_prediction";
export const MOST_WIN_LEADERBOARD = "admin/prediction/most_win_leaderboard";
export const MOST_BID_LEADERBOARD = "admin/prediction/most_bid_leaderboard";
export const GET_TOP_TEAM_GRAPH = "admin/prediction/get_top_team_graph";
export const GET_COINS_VS_USERS_GRAPH = "admin/prediction/get_coins_vs_users_graph";
export const UPDATE_PREDICTION = "admin/prediction/UPDATE_PREDICTION";

//Open Predictor module
export const OP_GET_PREDICTION_STATUS = "admin/open_predictor/get_prediction_status";
export const OP_UPDATE_PREDICTION_STATUS = "admin/open_predictor/update_prediction_status";
export const OP_GET_SEASON_LIST = "admin/open_predictor/get_season_list";
export const OP_CREATE_PREDICTION = "admin/open_predictor/create_prediction";
export const OP_UPDATE_PIN_PREDICTION = "admin/open_predictor/update_pin_prediction";
export const OP_PAUSE_PLAY_PREDICTION = "admin/open_predictor/pause_play_prediction";
export const OP_GET_ALL_PREDICTION = "admin/open_predictor/get_all_prediction";
export const OP_GET_PREDICTION_COUNTS = "admin/open_predictor/get_prediction_counts";
export const OP_GET_TRENDING_PREDICTIONS = "admin/open_predictor/get_trending_predictions";
export const OP_GET_PREDICTION_PARTICIPANTS = "admin/open_predictor/get_prediction_participants";
export const OP_SUBMIT_PREDICTION_ANSWER = "admin/open_predictor/submit_prediction_answer";
export const OP_DELETE_PREDICTION = "admin/open_predictor/delete_prediction";
export const OP_MOST_WIN_LEADERBOARD = "admin/open_predictor/most_win_leaderboard";
export const OP_MOST_BID_LEADERBOARD = "admin/open_predictor/most_bid_leaderboard";
export const OP_GET_TOP_CATEGORY_GRAPH = "admin/open_predictor/get_top_category_graph";
export const OP_GET_COINS_VS_USERS_GRAPH = "admin/open_predictor/get_coins_vs_users_graph";
export const OP_GET_CATEGORY_LIST_BY_STATUS = "admin/open_predictor/get_category_list_by_status";
export const OP_ADD_CATEGORY = "admin/open_predictor/add_category";
export const OP_DO_UPLOAD = "admin/open_predictor/do_upload";
export const OP_DO_UPLOAD_PROOF_IMAGE = "admin/open_predictor/do_upload_proof_image";
export const OP_GET_ALL_CATEGORY = "admin/open_predictor/get_all_category";
export const OP_DELETE_CATEGORY = "admin/open_predictor/delete_category";
export const OP_UPDATE_CATEGORY = "admin/open_predictor/update_category";
export const OP_UPDATE_PREDICTION_PROOF = "admin/open_predictor/update_prediction_proof";
export const OP_UPDATE_PREDICTION = "admin/open_predictor/update_prediction";

//Start fixed open predictor
export const FIXED_OP_GET_PREDICTION_STATUS = "admin/fixed_open_predictor/get_prediction_status";
export const FIXED_OP_UPDATE_PREDICTION_STATUS = "admin/fixed_open_predictor/update_prediction_status";
export const FIXED_OP_GET_SEASON_LIST = "admin/fixed_open_predictor/get_season_list";
export const FIXED_OP_CREATE_PREDICTION = "admin/fixed_open_predictor/create_prediction";
export const FIXED_OP_UPDATE_PIN_PREDICTION = "admin/fixed_open_predictor/update_pin_prediction";
export const FIXED_OP_PAUSE_PLAY_PREDICTION = "admin/fixed_open_predictor/pause_play_prediction";
export const FIXED_OP_GET_ALL_PREDICTION = "admin/fixed_open_predictor/get_all_prediction";
export const FIXED_OP_GET_PREDICTION_COUNTS = "admin/fixed_open_predictor/get_prediction_counts";
export const FIXED_OP_GET_TRENDING_PREDICTIONS = "admin/fixed_open_predictor/get_trending_predictions";
export const FIXED_OP_GET_PREDICTION_PARTICIPANTS = "admin/fixed_open_predictor/get_prediction_participants";
export const FIXED_OP_SUBMIT_PREDICTION_ANSWER = "admin/fixed_open_predictor/submit_prediction_answer";
export const FIXED_OP_DELETE_PREDICTION = "admin/fixed_open_predictor/delete_prediction";
export const FIXED_OP_MOST_WIN_LEADERBOARD = "admin/fixed_open_predictor/most_win_leaderboard";
export const FIXED_OP_MOST_BID_LEADERBOARD = "admin/fixed_open_predictor/most_bid_leaderboard";
export const FIXED_OP_GET_TOP_CATEGORY_GRAPH = "admin/fixed_open_predictor/get_top_category_graph";
export const FIXED_OP_GET_COINS_VS_USERS_GRAPH = "admin/fixed_open_predictor/get_coins_vs_users_graph";
export const FIXED_OP_GET_CATEGORY_LIST_BY_STATUS = "admin/fixed_open_predictor/get_category_list_by_status";
export const FIXED_OP_ADD_CATEGORY = "admin/fixed_open_predictor/add_category";
export const FIXED_OP_DO_UPLOAD = "admin/fixed_open_predictor/do_upload";
export const FIXED_OP_DO_UPLOAD_PROOF_IMAGE = "admin/fixed_open_predictor/do_upload_proof_image";
export const FIXED_OP_GET_ALL_CATEGORY = "admin/fixed_open_predictor/get_all_category";
export const FIXED_OP_DELETE_CATEGORY = "admin/fixed_open_predictor/delete_category";
export const FIXED_OP_UPDATE_CATEGORY = "admin/fixed_open_predictor/update_category";
export const FIXED_OP_UPDATE_PREDICTION_PROOF = "admin/fixed_open_predictor/update_prediction_proof";
export const FIXED_UPDATE_PRIZES = "admin/fixed_open_predictor/update_prizes";
export const DO_UPLOAD_SPONSOR_IMAGE = "admin/fixed_open_predictor/do_upload_sponsor_image";
export const GET_PREDICTION_PRIZES = "admin/fixed_open_predictor/get_prediction_prizes";
export const GET_LEADERBOARD_MASTER_DATA = "admin/fixed_open_predictor/get_leaderboard_master_data";
export const GET_OPEN_PREDICTOR_LEADERBOARD = "admin/fixed_open_predictor/get_open_predictor_leaderboard";
export const FIXED_GET_ATTEMPTS_VS_USERS_GRAPH = "admin/fixed_open_predictor/get_attempts_vs_users_graph";
export const FIXED_MOST_CORRECT_PREDICTIONS_LEADERBOARD = "admin/fixed_open_predictor/most_correct_predictions_leaderboard";
export const FIXED_MOST_ATTEMPTS_LEADERBOARD = "admin/fixed_open_predictor/most_attempts_leaderboard";
export const FIXED_UPDATE_PREDICTION = "admin/fixed_open_predictor/update_prediction";
//End fixed open predictor

//Match Closer
export const GET_SEASON_STATS = "admin/index.php/season/get_season_stats";
export const UPDATE_MATCH_STATUS = "admin/index.php/season/update_match_status";
export const UPDATE_PLAYER_MATCH_SCORE = "admin/index.php/season/update_player_match_score";
export const RECALCULATE_MATCH_SCORE = "admin/index.php/season/recalculate_match_score";

//Pickem
export const CREATE_LEAGUE = "admin/pickem/manual_league/create_league";
export const GET_LEAGUES = "admin/pickem/get_pickem_leagues";
export const GET_LEAGUES_NEW = "admin/pickem/manual_league/get_leagues";
export const DO_UPLOAD_FLAG = "admin/pickem/manual_team/do_upload/flag";
export const CREATE_TEAM_STATS = "admin/pickem/manual_team/create_team_stats";
export const GET_TEAMS = "admin/pickem/manual_team/get_teams";
export const CREATE_PICKEM = "admin/pickem/create_pickem";
export const GET_ALL_PICKEM = "admin/pickem/get_all_pickem";
export const UPDATE_PICKEM_RESULT = "admin/pickem/update_pickem_result";
export const DELETE_PICKEM = "admin/pickem/delete_pickem";
export const EDIT_TEAM = "admin/pickem/manual_team/edit_team";
export const EDIT_LEAGUE = "admin/pickem/manual_league/edit_league";
export const GET_UNPUBLISHED_MATCHES = "admin/pickem/get_unpublished_matches";
export const PUBLISH_MATCH_PICKEM = "admin/pickem/publish_match_pickem";
export const GET_PICKEM_PARTICIPANTS = "admin/pickem/get_pickem_participants";
export const GET_COIN_CONFIG = "admin/pickem/get_coin_config";
export const SAVE_COIN_CONFIG = "admin/pickem/save_coin_config";
export const GET_TRENDING_PICKEMS = "admin/pickem/get_trending_pickems";
export const GET_PICKEM_COUNTS = "admin/pickem/get_pickem_counts";
export const MOST_WIN_LEADERBOARD_PEMS = "admin/pickem/most_win_leaderboard";
export const MOST_BID_LEADERBOARD_PEMS = "admin/pickem/most_bid_leaderboard";
export const PEM_GET_COINS_VS_USERS_GRAPH = "admin/pickem/get_coins_vs_users_graph";
export const PEM_GET_TOP_TEAM_GRAPH = "admin/pickem/get_top_team_graph";

//Reports
export const EXPORT_REPORT = 'admin/index.php/report/export_report';

//Cancel Collection
export const CANCEL_COLLECTION = "admin/index.php/contest/cancel_collection"
export const CANCEL_CONTEST = "admin/index.php/contest/cancel_contest"
//Match Delay 
export const UPDATE_FIXTURE_CUSTOM_MESSAGE = "admin/index.php/season/update_fixture_custom_message";
export const UPDATE_FIXTURE_DELAY = "admin/index.php/season/update_fixture_delay";

//Update salary 
export const GET_SEASON_PLAYERS = "admin/index.php/season/get_season_players";
export const UPDATE_SEASON_PLAYER = "admin/index.php/season/update_season_player";
export const PUBLISH_FIXTURE = "admin/index.php/season/publish_fixture";

//Create Minileague
export const GET_LIVE_UPCOMING_LEAGUE = "admin/index.php/tournament/get_live_upcoming_leagues";
export const GET_LEAGUE_SEASIONS = "admin/index.php/tournament/get_league_seasons";


export const GET_LEAGUE_LIST_MINILEAGUE = "admin/index.php/mini_league/get_all_leagues";
export const GET_LEAGUE_SEASIONS_MINILEAGUE = "admin/index.php/mini_league/get_league_seasons";
export const CREATE_MINILEAGUE = "admin/index.php/mini_league/create_mini_league";
export const GET_MINILEAGUE_LIST = "admin/index.php/mini_league/get_mini_league_list";
export const UPLOAD_MINILEAGUE_SPONSER = "admin/index.php/mini_league/do_upload_sponsor";
export const GET_MINILEAGUE_DETAIL = "admin/index.php/mini_league/get_mini_league_detail";
export const GET_MINILEAGUE_LEADERBOARD = "admin/index.php/mini_league/mini_league/get_mini_league_leaderboard";
export const CREATE_F2P_CONTEST = "admin/index.php/contest/freetoplay/create_contest";
export const UPDATE_MINILEAGUE = "admin/index.php/mini_league/update_mini_league";
export const UPDATE_MINILEAGUE_FIXTURE = "admin/index.php/mini_league/add_mini_league_seasons ";

//Change Password
export const CHANGE_PASSWORD = 'admin/setting/change_password';

//System User
export const GET_USERS = "admin/systemuser/get_users";
export const CREATE_USER = "admin/systemuser/create_user";
export const GET_CONTEST_DETAIL = "admin/systemuser/get_contest_detail";
export const GET_SYSTEM_USERS_FOR_CONTEST = "admin/systemuser/get_system_users_for_contest";
export const GET_CONTEST_JOINED_SYSTEM_USERS = "admin/systemuser/get_contest_joined_system_users";
export const JOIN_SYSTEM_USERS = "admin/systemuser/join_system_users";
export const DELETE_USER = "admin/systemuser/delete_user";
export const UPDATE_USER = "admin/systemuser/update_user";
export const SU_DO_UPLOAD = "admin/systemuser/do_upload";
export const SU_REMOVE_PROFILE_IMAGE = "admin/systemuser/remove_profile_image";

export const UPLOAD_MINILEAGUE_BGIMAGE = "admin/index.php/mini_league/do_upload_bg";

//Forgot Password 
export const RESET_PASSWORD = "admin/index.php/auth/reset_password";
// Start New Communication Dashboard
export const GET_SEGEMENTATION_TEMPLATE_LIST = "admin/communication_dashboard/user_segmentation/get_segementation_template_list";
// End New Communication Dashboard
//Admin roles
export const GET_ADMIN_ROLES = "admin/index.php/roles/admin_roles_key";
export const ROLES_LIST = "admin/index.php/roles/roles_list";
export const ADD_ROLES = "admin/index.php/roles/add_roles";
export const GET_ROLES_DETAIL = "admin/index.php/roles/get_roles_detail";
export const DELETE_ROLES = "admin/index.php/roles/delete_roles";
export const UPDATE_ROLES = "admin/index.php/roles/update_roles";

//Spinthewheel
export const WHEEL_SLICES_LIST = "admin/index.php/spinthewheel/wheel_slices_list";
export const SLICES_UPDATE = "admin/index.php/spinthewheel/slices_update";

//Distributor
export const GET_ADMIN_LIST = "admin/index.php/distributor/get_admin_list";
export const ADD_DISTRIBUTOR = "admin/index.php/distributor/add_admin";
export const GET_ADMIN_DETAIL = "admin/index.php/distributor/get_admin_detail";
export const GET_RECHARGE_REQUEST_LIST = "admin/index.php/distributor/get_recharge_request_list";
export const DISTRIBUTOR_IMAGE_UPLOAD = 'admin/index.php/distributor/do_upload';
export const RECHARGE_REQUEST = 'admin/index.php/distributor/do_recharge';
export const APPROVE_RECHARGE_REQUEST = 'admin/index.php/distributor/approve_recharge';
export const DISTRIBUTOR_RECHARGE_USER = "admin/index.php/distributor/recharge_user";
export const DISTRIBUTOR_SEARCH_USER = "admin/index.php/distributor/get_search_user";
export const DISTRIBUTOR_RECHARGE_LIST = "admin/index.php/distributor/get_recharge_list";
export const DISTRIBUTOR_STATE_LIST = "admin/index.php/distributor/get_all_state_by_country";
export const CHANGE_DISTRIBUTOR_STATUS = "admin/index.php/distributor/change_status";
export var ADMIN_ROLE = '';
export class Role {
    static setRole(data) {
        ADMIN_ROLE = data;
    }
}
export const RECHARGE_SLIP = S3_SERVER_URL + 'upload/recharge_slip/'

// Start affiliate
export const AFFI_UPDATE_AFFILIATE = "admin/affiliate_users/update_affiliate";
export const AFFI_GET_PENDING_AFFILIATE = "admin/affiliate_users/get_pending_affiliate";
export const AFFI_GET_COMMISSION_GRAPH = "admin/affiliate_users/get_commission_graph";
export const AFFI_USERS = "admin/affiliate_users/users";
export const AFFI_GET_AFFILIATE_RECORDS = "admin/affiliate_users/get_affiliate_records";
export const AFFI_GET_SIGNUP_GRAPH = "admin/affiliate_users/get_signup_graph";
export const AFFI_GET_DEPOSIT_GRAPH = "admin/affiliate_users/get_deposit_graph";
// End affiliate

export const UPDATE_OTP_BLOCKED_USERS = "admin/user/update_otp_blocked_users";

//Start configuration
export const GET_SPORTS_HUB_LIST = "admin/setting/get_sports_hub_list";
export const UPDATE_SPORTS_HUB = "admin/index.php/setting/update_sports_hub";
export const GET_BANNER_IMAGE_DATA = "admin/setting/get_banner_image_data";
export const UPDATE_BANNER_IMAGE_DATA = "admin/setting/update_banner_image_data";
export const SETT_BANNER_UPLOAD = "admin/setting/banner_upload";
export const SETT_REMOVE_BANNER = "admin/setting/remove_banner";
export const HUB_IMAGE_DO_UPLOAD = "admin/setting/hub_image_do_upload";
export const TOGGLE_BANNER_IMAGE_STATUS = "admin/setting/toggle_banner_image_status";
export const GET_SPORTS_DISPLAY_NAME = "admin/setting/get_sports_display_name";
export const UPDATE_SPORTS_DISPLAY_NAME = "admin/setting/update_sports_display_name";
export const REVERT_TO_ORIGNAL_HUB = "admin/setting/revert_to_orignal_hub";
export const GET_HUB_ICON_BANNER = "admin/setting/get_hub_icon_banner";
export const WLT_GET_CONTENT = "admin/setting/get_content";
export const WLT_UPDATE_CONTENT = "admin/setting/update_content";
export const GET_MIN_MAX_WITHDRAWL_LIMIT = "admin/setting/get_min_max_withdrawl_limit";
export const UPDATE_MIN_MAX_WITHDRAWL_LIMIT = "admin/setting/update_min_max_withdrawl_limit";
//End configuration
export const GET_EMAIL_SETTING = "admin/setting/get_email_setting";
export const SAVE_EMAIL_SETTING_STATUS = "admin/setting/save_email_setting_status";

//Start buy Coin module
export const ADD_PACKAGE = "admin/index.php/coins_package/add_package";
export const PACKAGE_UPDATE = "admin/index.php/coins_package/package_update";
export const PACKAGE_LIST = "admin/index.php/coins_package/package_list";
export const PACKAGE_REDEEM_LIST = "admin/index.php/coins_package/package_redeem_list";
//End buy coin module

export const GET_APP_ADMIN_CONFIG = "admin/setting/get_app_admin_config";
export const GET_APP_MASTER_LIST = "admin/auth/get_app_master_list";
export const SAVE_CONFIG = "admin/setting/save_config";
export const GET_DATECONFIG = "admin/setting/get_date_setting";
export const SAVE_DATECONFIG = "admin/setting/change_date_time";
export const FLUSH_CACHE = "admin/setting/flush_cache";

export const DO_UPLOAD_SPONSOR_CONTEST_DTL = "admin/index.php/contest/contest_template/do_upload_sponsor_contest_dtl";
//Start code for Add contest category
export const CREATE_GROUP = "admin/contest/contest_template/create_group";
export const UPDATE_GROUP = "admin/contest/contest_template/update_group";
export const GET_GROUP = "admin/contest/contest_template/get_group";
export const UPLOAD_GROUP_ICON = "admin/contest/contest_template/upload_group_icon";
export const REMOVE_GROUP_ICON = "admin/contest/contest_template/remove_group_icon";
export const DELETE_GROUP = "admin/contest/contest_template/inactive_group";
//End code for Add contest category
export const UPLOAD_SYSTEMUSER = "admin/index.php/systemuser/upload_systemuser";

//Referral set prize
export const GET_REFERRAL_PRIZES = "admin/referral/get_referral_prizes";
export const REFERRAL_UPDATE_PRIZES = "admin/referral/update_prizes";
export const GET_REFERRAL_LEADERBOARD_MASTER_DATA = "admin/referral/get_referral_leaderboard_master_data";
export const GET_REFERRAL_LEADERBOARD = "admin/referral/get_referral_leaderboard";

//KYC edit module
export const UPDATE_PAN_INFO = "admin/user/update_pan_info";
export const UPDATE_BANK_AC_DETAIL = "admin/user/update_bank_ac_detail";
export const UPLOAD_PAN = "admin/user/upload_pan";
export const UPLOAD_BANK_DOCUMENT = "admin/user/upload_bank_document";
//Network Game 
export const GET_ALL_NETWORK_CONTEST = 'admin/nw_contest/get_all_network_contest';
export const PUBLISH_NETWORK_CONTEST = 'admin/nw_contest/publish_network_contest';
export const GET_NETWORK_CONTEST_DETAILS = 'admin/nw_contest/get_network_contest_details';
export const SL_GET_CONTEST_DETAIL = 'admin/sl_contest/get_contest_detail';
export const GET_NETWORK_CONTEST_PARTICIPANTS = 'admin/nw_contest/get_network_contest_participants';
export const SL_GET_NETWORK_CONTEST_PARTICIPANTS = 'admin/sl_contest/get_contest_participants';
export const GET_NETWORK_LINEUP_DETAIL = 'admin/nw_contest/get_network_lineup_detail';
export const SL_GET_NETWORK_LINEUP_DETAIL = 'admin/sl_contest/get_user_lineup_detail';

export const GET_NEXT_WEEK = 'admin/sl_contest/get_next_week';

export const GET_CONTEST_COMMISSION_HISTORY = 'admin/nw_contest/get_contest_commission_history';
export const GET_ALL_NW_CONTEST_REPORT = 'admin/nw_contest/get_all_nw_contest_report';
export const GET_NW_CONTEST_REPORT_FILTERS = 'admin/nw_contest/get_nw_contest_report_filters';
export const GET_NW_COLLECTION_LIST = 'admin/nw_contest/get_nw_collection_list';
export const EXPORT_NW_CONTEST_REPORT = 'admin/nw_contest/export_nw_contest_report';
//Upload Cumtom notification img
export const HEADER_IMAGE = "admin/communication_dashboard/user_segmentation/do_upload/header_image";
export const BODY_IMAGE = "admin/communication_dashboard/user_segmentation/do_upload/body_image";

//Netwok game system user
export const NG_GET_CONTEST_DETAIL = "admin/nw_contest/nw_systemuser/get_contest_detail";
export const NG_GET_SYSTEM_USERS_FOR_CONTEST = "admin/nw_contest/nw_systemuser/get_system_users_for_contest";
export const NG_GET_CONTEST_JOINED_SYSTEM_USERS = "admin/nw_contest/nw_systemuser/get_contest_joined_system_users";
export const NG_JOIN_SYSTEM_USERS = "admin/nw_contest/nw_systemuser/join_system_users";



//Leaderboards
export const GET_REFERRAL_RANK = "admin/dashboard/get_referral_rank";
export const GET_APP_USAGE_DATA = "admin/dashboard/get_app_usage_data";
export const GET_ALL_SEASON_WEEKS = "admin/season/get_all_season_weeks";
export const GET_WEEK_SEASONS = "admin/season/get_week_seasons";

export const UPDATE_WITHDRAWAL_STATUS = 'admin/index.php/finance/update_withdrawal_status';
export const GET_MATCH_REPORT = 'admin/index.php/report/get_match_report';
//GST Module

export const GET_STATE_LIST = 'admin/index.php/user/get_state_list';
export const GET_COMPLETED_FIXTURE = 'admin/index.php/gst/get_completed_fixture';
export const GET_COMPLETED_CONTEST = 'admin/index.php/gst/get_completed_contest';
export const GET_GST_REPORT = 'admin/index.php/gst/gst_report';
export const GET_DOWNLOAD_REPORT = 'admin/index.php/gst/gst_invoice_download';
export const GET_EXPORT_GST_REPORT = 'admin/index.php/gst/export_gst_invoice_report';
export const GET_SEARCH_USER = 'admin/index.php/gst/get_search_user';
export const GET_DASHBOARD = 'admin/index.php/gst/get_gst_dashboard';


//New Dlt SMS template
export const GET_SMS_TEMPLATE = 'admin/communication_dashboard/user_segmentation/get_sms_template';
export const UPDATE_SMS_TEMPLATE = 'admin/communication_dashboard/user_segmentation/update_sms_template';

//SCRATCH_WIN
export const CHANGE_SCRATCH_WIN_STATUS = 'admin/scratchwin/change_scratch_win_status';
export const GET_SCRATCH_CARD_LIST = 'admin/scratchwin/get_scratch_card_list';
export const DELETE_SCRATCH_CARD = 'admin/scratchwin/delete_scratch_card';
export const ADD_SCRATCH_CARD = 'admin/scratchwin/add_scratch_card';
export const UPDATE_SCRATCH_CARD = 'admin/scratchwin/update_scratch_card';

export const UPDATE_NEW_MASTER_SCORING_POINTS = 'admin/scoring/update_new_master_scoring_points';
export const JOIN_MULTIPLE_SYSTEM_USERS = "admin/nw_contest/nw_systemuser/join_multiple_system_users";
export const GET_ADMIN_AUTH = "admin/setting/get_admin_auth";

//League Management

export const GET_FILTER_MASTER = "admin/league/get_filter_master";
export const GET_SPORT_LEAGUE = "admin/league/get_sport_leagues";
export const CHANGE_LEAGUE_STATUS = "admin/league/change_league_status";
export const GET_LEAGUE_WEEK = "admin/league/get_league_week";
export const ADD_LEAGUE_WEEK = "admin/league/add_league_week";
export const GET_LEAGUE_FIXTURE = "admin/league/get_league_fixture";
export const DELETE_LEAGUE_WEEK = "admin/league/delete_league_week";
export const ADD_FIXTURE_IN_LEAGUE_WEEK = "admin/league/add_fixture_in_league_week";
export const GET_LEAGUE_SETTING = "admin/league/get_league_setting";
export const SAVE_LEAGUE_SETTING = "admin/league/save_league_setting";

// Mini Leagues
export const GET_SPORT_LEAGUES_SL = "admin/sl_contest/get_sport_leagues";
export const GET_ALL_WEEK_BY_LEAGUE_ID = "admin/sl_contest/get_all_week_by_league_id";
export const GET_MINI_LEAGUE = "admin/sl_contest/get_mini_league";
export const CREATE_MINI_LEAGUE = "admin/sl_contest/create_mini_league";
export const SAVE_MINI_LEAGUE_PRIZE = "admin/sl_contest/save_mini_league_prize";
export const DELETE_MINI_LEAGUE = "admin/sl_contest/delete_mini_league";

//Draft start pack
export const GET_DRAFTPACK_MASTER_DATA = "admin/draftpack/get_draftpack_master_data";
export const GET_DRAFTPACK_DETAIL = "admin/draftpack/get_draftpack_detail";
export const SAVE_DRAFTPACK = "admin/draftpack/save_draftpack";
//Competitions
export const CONTEST_TEMPLATE_LIST = "admin/sl_contest/Competition/contest_template_list";
export const CHANGE_COMPETITION_STATUS = "admin/sl_contest/Competition/change_competition_status";
export const GET_ALL_WEEK = "admin/season/get_all_week";
export const GET_PRIZE_TYPE = "admin/sl_contest/Competition/get_prize_type";
export const CREATE_COMPETITION = "admin/sl_contest/Competition/create_competition";
export const CREATE_PRIVATE_CONTEST = "admin/sl_contest/create_private_contest";
export const GET_CONTEST_FILTER_COMP = "admin/sl_contest/get_contest_filter";
export const GET_CONTEST_LIST_COMP = "admin/sl_contest/get_contest_list";
export const GET_DAILY_REWARD_PLAYER = "admin/shop/get_daily_reward_player";
export const SAVE_DAILY_REWARD_PLAYER = "admin/shop/save_daily_reward_player";
export const GET_PROMOTION = "admin/shop/get_promotion";
export const CREATE_PROMOTION = "admin/shop/create_promotion";
export const GET_ALL_ROSTER = "admin/roster/get_all_roster";
export const GET_COIN_BUNDEL = "admin/shop/get_coin_bundel";
export const CREATE_FX_COIN_BUNDLE = "admin/shop/create_fx_coin_bundle";
export const REMOVE_PROMOTION = "admin/shop/remove_promotion";
export const REMOVE_COIN_BUNDEL = "admin/shop/remove_coin_bundel";

export const GET_DRAFTPACK_LIST = "admin/draftpack/get_draftpack_list";

export const PC_GET_MASTER_DATA = "admin/promo_code/get_master_data";
export const PC_GET_PROMO_CODES = "admin/promo_code/get_promo_codes";
export const PC_UPDATE_END_DATE = "admin/promo_code/update_end_date";
export const PC_NEW_PROMO_CODE = "admin/promo_code/new_promo_code";
export const PC_PROMO_CODE_DETAIL = "admin/promo_code/get_promo_code_detail";
export const PC_GET_AFFILIATE_MASTER_DATA = "admin/referral/get_referral_master_data";
export const PC_UPDATE_AFFILIATE_MASTER_DATA = "admin/referral/update_referral_master_data";
export const PC_REFERAL_REPORT = "admin/referral/referral_report";
export const PC_GET_USER_REFERAL_DETAILS = "admin/referral/get_user_referral_details";
export const PC_CHANGE_PLAYERCARD_STATUS = "admin/draftpack/change_draftpack_status";
export const PC_DO_UPLOAD = "admin/draftpack/do_upload";
export const PC_EXPORT_PROMOCODE_USERLIST = "admin/promo_code/export_promo_code_user_list?";
export const GET_BUNDLE_PURCHASE_USERS = "admin/shop/get_bundle_purchase_users";
export const DO_UPLOAD_SHOP = "admin/shop/do_upload";

export const BANK_REPORT = "admin/report/get_winning_report";
export const UPDATE_BANK_NOTE = "admin/report/update_winning_note"
export const UPDATE_WINNING_STATUS = "admin/report/update_claim_status"

