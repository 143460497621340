import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { AppHeader, AppSidebar, AppSidebarForm, AppSidebarHeader, AppSidebarNav } from '@coreui/react';
import navigation from '../../_nav';
import navigation2 from '../../_nav2';
import Notification from 'react-notify-toast';
import routes from '../../routes';
import DefaultHeader from './DefaultHeader';
import _ from 'lodash';
import WSManager from "../../helper/WSManager";
import HF from '../../helper/HelperFunction';

class DefaultLayout extends Component { 
  constructor(props) {
    super(props)
    this.state = {
      navPosting: false,
    }
  }

  removeItem = (idx) => {
    delete navigation.items[idx]
  }

  removeNavigation = (modAccess, accessName, idx) => {
    if (modAccess.includes(accessName)) {
      let msData = HF.getMasterData()
      if (accessName == 'coins' && (_.isUndefined(msData.allow_coin) || msData.allow_coin == '0')) {
        this.removeItem(idx)
      }
      if (accessName == 'sports_predictor' && (_.isUndefined(msData.allow_prediction) || msData.allow_prediction == '0')) {
        this.removeItem(idx)
      }
      if (accessName == 'open_predictor_with_pool' && (_.isUndefined(msData.allow_open_predictor) || msData.allow_open_predictor == '0')) {
        this.removeItem(idx)
      }
      if (accessName == 'open_predictor_with_prize' && (_.isUndefined(msData.allow_fixed_open_predictor) || msData.allow_fixed_open_predictor == '0')) {
        this.removeItem(idx)
      }
      if (accessName == 'distributors' && (_.isUndefined(msData.allow_distributor) || msData.allow_distributor == '0')) {
        this.removeItem(idx)
      }
      if (accessName == 'multigame' && (_.isUndefined(msData.allow_multigame) || msData.allow_multigame == '0')) {
        this.removeItem(idx)
      }
      if (accessName == 'free_to_play' && (_.isUndefined(msData.allow_free_to_play) || msData.allow_free_to_play == '0')) {
        this.removeItem(idx)
      }
      if (accessName == 'network_game' && (_.isUndefined(msData.allow_network_fantasy) || msData.allow_network_fantasy == '0')) {
        this.removeItem(idx)
      }
      if (accessName == 'pickem' && (_.isUndefined(msData.allow_pickem) || msData.allow_pickem == '0')) {
        this.removeItem(idx)
      }
      
      if (accessName == 'affiliate' && (_.isUndefined(msData.allow_affiliate) || msData.allow_affiliate == '0')) {
        this.removeItem(idx)
      }
      
      if (accessName == 'accounting' && (_.isUndefined(msData.int_version) || msData.int_version == '1' || msData.allow_gst == '0')) {
        this.removeItem(idx)
      }
    }
  }

  removeChieldNav = (c_menu_name, idx, cidx) => {
    let msData = HF.getMasterData()
    if (c_menu_name === 'Spin the wheel' && !_.isUndefined(msData.allow_spin) && msData.allow_spin == '0' && !_.isUndefined(msData.allow_coin) && msData.allow_coin == '1') {
      if (!_.isUndefined(navigation.items[idx]))
        delete navigation.items[idx]['children'][cidx]
    }
    if (!_.isUndefined(HF.getMasterData().pl_allow) && HF.getMasterData().pl_allow == '0' && (c_menu_name === 'System User Reports' || c_menu_name === 'System users')) {
      if (!_.isUndefined(navigation.items[idx]))
        delete navigation.items[idx]['children'][cidx]
    }
    /**Start to remove international version nav */
    if (HF.getIntVersion() == '1' && (c_menu_name === 'User Money Paid' || c_menu_name === 'User Deposit Amount' || c_menu_name === 'Withdrawal List')) {
      if (!_.isUndefined(navigation.items[idx]))
        delete navigation.items[idx]['children'][cidx]
    }
    /**End to remove international version nav */
    if (HF.allowScratchWin() == '0' && (c_menu_name === 'Manage Reward')) {
      if (!_.isUndefined(navigation.items[idx]))
        delete navigation.items[idx]['children'][cidx]
    }
  }

  componentDidMount = () => {
    /*Start code for admin role access*/
    let modAccess = WSManager.getKeyValueInLocal("module_access")
    _.map(navigation.items, (navItem, idx) => {
      let smallName = navItem ? navItem.name.toLowerCase() : ''
      let accessName = navItem ? smallName.replace(/ /g, '_') : ''
      if (!_.isNull(modAccess) && !_.isUndefined(modAccess)) {
        if (accessName.includes("'")) {
          accessName = accessName.replace("'", '')
        }
        if (accessName !== 'change_password') {
          if (accessName == "user_management" && modAccess.includes('user_wallet_manage')) {
            //show  User management
          } else if (!modAccess.includes(accessName)) {
              delete navigation.items[idx]
          }
          /**Start code to remove if not allowed from backend */
          this.removeNavigation(modAccess, accessName, idx)
          /**End code to remove if not allowed from backend */
        }

        if (!_.isEmpty(navItem) && !_.isUndefined(navItem.children)) {
          _.map(navItem.children, (navChild, cidx) => {
            if (!_.isUndefined(navChild) && !_.isUndefined(navChild.name))
            {
              /**Start code to remove chield menu if not allowed from backend */
              this.removeChieldNav(navChild.name, idx, cidx)
              /**End code to remove chield menu if not allowed from backend */
            }
          })
        }

      }
    })    
    /*End code for admin role access*/
    this.setState({ navPosting : true })
  }

  render() {
    return (
      <div className="app">
      <Notification options={{ zIndex: 1060 }} />
          <AppHeader fixed>
            <DefaultHeader />
          </AppHeader>
          <div className="app-body">
            {
              this.state.navPosting &&
              <AppSidebar fixed display="lg">
                <AppSidebarHeader />
                <AppSidebarForm />
                <AppSidebarNav className="animate-bottom" navConfig={(WSManager.getRole()==1)? navigation : navigation2} {...this.props} />
              </AppSidebar>
            }
            <main className="main">
              <Container fluid>              
                <Switch>
                  {routes.map((route, idx) => {
                      return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                          <route.component {...props} />
                        )} />)
                        : (null);
                    },
                  )}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
                
              </Container>
            </main>
          </div>        
      </div>
    );
  }
}

export default DefaultLayout;
