import * as NC from "../src/helper/NetworkingConstants";
let items = {
  items: [    
    {
      name: 'Change Password',
      url: '/change-password',
    },
    {
      name: 'User Management',
      url: '/user_management',
      children: [{
          name: 'Manage User',
          url: '/manage_user',
    
        },
      ],
    }
  ]
};








export default items;
