import * as NC from "../src/helper/NetworkingConstants";
import WSManager from "../src/helper/WSManager";

const items = {
  items: [{
    name: 'Dashboard',
    url: '/dashboard',
  },
  {
    name: 'DFS',
    url: '/game_center',
    children: (NC.ALLOW_DFS == 1) ? [{
      name: 'Fixture',
      url: '/game_center/DFS',
    },
    {
      name: 'Contest Dashboard',
      url: '/contest/contestlist',
    },
    {
      name: 'Contest Template',
      url: '/contesttemplate',
    },
    {
      name: 'System User Reports',
      url: '/system_user_report',
    },
    {
      name: 'Contest category',
      url: '/game_center/category',
    }

    ] : [{
      name: 'Fixture',
      url: '/game_center/DFS',
    },
    {
      name: 'Contest Dashboard',
      url: '/contest/contestlist',
    }

    ],
  },
  {
    name: 'Leaderboard',
    url: '/leaderboard',
    children: [{
      name: 'Referral Leaderboard',
      url: '/leaderboard/referral'
    },
    {
      name: 'Depositors Leaderboard',
      url: '/leaderboard/depositors'
    },
    {
      name: 'Winnings Leaderboard',
      url: '/leaderboard/winnings'
    },
    {
      name: 'Time Spent Leaderboard',
      url: '/leaderboard/timespent'
    },
    // {
    //   name: 'Feedback Leaderboard',
    //   url: '/leaderboard/feedback'
    // },
    {
      name: 'Teams Leaderboard',
      url: '/leaderboard/topteams'
    },
    {
      name: 'Withdrawal Leaderboard',
      url: '/leaderboard/withdrawal'
    },
    ]
  }
  ]
};

items.items.push({
  name: 'Network Game',
  url: '/network-game',
  children: [{
    name: 'Network Contest',
    url: '/network-game',

  },
  {
    name: 'Contest Report',
    url: '/network-game/contest-report',

  },
  {
    name: 'Commission History',
    url: '/network-game/commission-history',

  }
  ],
});

items.items.push({
  name: 'Admin Role Management',
  url: '/manage-role',
  children: [{
    name: 'Add Role',
    url: '/admin-role/add-role',

  },
  {
    name: 'Manage Roles',
    url: '/manage-role',
  }
  ],
})


if (WSManager.getKeyValueInLocal('ALLOW_COIN_MODULE') == 1) {
  items.items.push({
    name: "Pick'em",
    url: '/pickem',
    children: [{
      name: 'Dashboard',
      url: '/pickem/dashboard',
    },
    {
      name: 'View Picks',
      url: '/pickem/picks',
    },
    {
      name: 'Leagues/Players',
      url: '/pickem/leagues',
    }
    ],
  });
}


items.items.push({
  name: 'Sports Predictor',
  url: '/prediction',
  children: NC.SHOW_PREDICTION_CHILD == "1" ? [{
    name: 'Dashboard',
    url: '/prediction/dashboard',
  },
  {
    name: 'Fixture',
    url: '/prediction/fixture',
  },
  ] : null,
});



items.items.push({
  name: 'Open Predictor with Pool',
  url: '/open-predictor',
  children: NC.SHOW_OP_PREDICTION_CHILD == "1" ? [{
    name: 'Dashboard',
    url: '/open-predictor/dashboard',
  },
  {
    name: 'Category',
    url: '/open-predictor/category',
  },
  ] : null,
});


items.items.push({
  name: 'Open Predictor with Prize',
  url: '/prize-open-predictor',
  children: NC.SHOW_OP_PREDICTION_CHILD == "1" ? [{
    name: 'Dashboard',
    url: '/prize-open-predictor/dashboard',
  },
  {
    name: 'Category',
    url: '/prize-open-predictor/category',
  },
  ] : null,
});



items.items.push({
  name: 'Multigame',
  url: '/multigame',
  children: [{
    name: 'Fixtures',
    url: '/multigame/Fixtures',

  },
  {
    name: 'Contest  List',
    url: '/contest/multigamecontest',

  }
  ],
})


var marketing_child_menu = [{
  name: 'Referral Amount',
  url: '/marketing/referral_amount',
},
{
  name: 'Promo code',
  url: '/marketing/promo_code',
}
]
if (NC.ALLOW_COMMUNICATION_DASHBOARD > 0) {
  marketing_child_menu.push({
    name: 'Communication Dashboard',
    url: '/marketing/communication_dashboard',

  })

  marketing_child_menu.push({
    name: 'Communication Campaign',
    url: '/marketing/new_campaign',
  })

  marketing_child_menu.push({
    name: 'Manage Templates',
    url: '/marketing/custome-template',
  })
}

if (NC.ALLOW_REFERRAL_SETPRIZE == 1) {
  marketing_child_menu.push({
    name: 'Referral Setprize',
    url: '/marketing/referral_setprize',
  })
  marketing_child_menu.push({
    name: 'Referral Leaderboard',
    url: '/marketing/referral_leaderboard',
  })
}


items.items.push({
  name: 'Marketing',
  url: '/marketing',
  children: marketing_child_menu,
});


if (NC.ALLOW_DEAL) {
  items.items.push({
    name: 'Deals',
    url: '/deals',
    children: [{
      name: 'Deals',
      url: '/deals/deal_list',

    },],
  });
}


items.items.push({
  name: 'User Management',
  url: '/user_management',
  children: [{
    name: 'Manage User',
    url: '/manage_user',

  },
    // {
    //   name: 'Add User',
    //   url: '/add_user',

    // },
    // {
    //   name: 'System users',
    //   url: '/system-users/userslist',
    // }
  ],
})

items.items.push({
  name: 'League Management',
  url: '/league-management',
})

items.items.push({
  name: 'Season Long Contests',
  url: '/seasonlong_contests',
  children: [{
    name: 'Private Contest',
    url: '/seasonlong_contests',
  }
  ],
});

items.items.push({
  name: 'Content Management',
  url: '/cms',
  children: [
    // {
    //   name: 'Lobby Banner',
    //   url: '/cms/lobby_banner/',
    // },
    // {
    //   name: 'App Banner',
    //   url: '/cms/app_banner/',
    // },
    // {
    //   name: 'Manage Front Image',
    //   url: '/cms/background_image',
    // },
    {
      name: 'CMS',
      url: '/cms/cms',
    },
    // {
    //   name: 'Hub Page',
    //   url: '/cms/hub-page',
    // },
    // {
    //   name: 'Lobby',
    //   url: '/cms/lobby',
    // },
  ],
})

items.items.push({
  name: 'Report',
  url: '/report/',
  children: [
  //   name: 'User Report',
  //   url: '/report/user_report',
  // },
  // {
  //   name: 'User Money Paid',
  //   url: '/report/user_money_paid',
  // },
  // {
  //   name: 'User Deposit Amount',
  //   url: '/report/user_deposit_amount',
  // },
  // {
  //   name: 'Referral Report',
  //   url: '/report/referral_report',
  // },
  {
    name: 'Bank Report',
    url: '/report/contest_report',
  },
  // {
  //   name: 'Match Report',
  //   url: '/report/match_report',
  // },
  ],
})

items.items.push({
  name: 'Manage Finance',
  url: '/finance',
  children: [
    // {
    //   name: 'Withdrawal List',
    //   url: '/finance/withdrawal_list',
    // },
    {
      name: 'Transaction List',
      url: '/finance/transaction_list',
    }
  ],
})

var coin_child_menu = [{
  name: 'Dashboard',
  url: '/coins/dashboard',
},
{
  name: 'Redeem',
  url: '/coins/redeem',
},
{
  name: 'Promotions',
  url: '/coins/promotions',
},
]


coin_child_menu.push({
  name: 'Spin the wheel',
  url: '/coins/spinthewheel',
})


if (NC.ALLOW_BUY_COIN > 0) {
  coin_child_menu.push({
    name: 'Buy Coins',
    url: '/coins/buy-coins',
  })
}


items.items.push({
  name: 'Coins',
  url: '/coins',
  children: coin_child_menu
});


items.items.push({
  name: 'Settings',
  url: '/settings',
  children: [
    // {
    //   name: 'Teams',
    //   url: '/Teams',
    // },
    {
      name: 'Add Merchandise',
      url: '/merchandise',
    },
    // {
    //   name: 'Mini Leagues',
    //   url: '/mini-leagues',
    // },
    // {
    //   name: 'Prizes',
    //   url: '/prizes',
    // },
    // {
    //   name: 'Manage Scoring',
    //   url: '/manage_scoring'
    // },
    // {
    //   name: 'Minimum Withdrawal',
    //   url: '/settings/minimum-withdrawal',
    // },
    // {
    //   name: 'Wallet',
    //   url: '/settings/wallet',
    // },
    // {
    //   name: 'Email',
    //   url: '/settings/email',
    // },
    // {
    //   name: 'Manage Reward',
    //   url: '/settings/reward',
    // },
  ],
})


items.items.push({
  name: 'Distributors',
  url: '/distributors',
  children: null,
})


items.items.push({
  name: 'Affiliate',
  url: '/affiliates',
})

items.items.push({
  name: 'Change Password',
  url: '/change-password',
})

items.items.push({
  name: 'Accounting',
  url: '/accounting',
  children: [{
    name: 'GST Dashboard',
    url: '/accounting/dashboard',
  },
  {
    name: 'Report',
    url: '/accounting/reports',
  }
  ],
})

items.items.push({
  name: 'Draft Starter Pack',
  url: '/draft-starter-pack'
})

items.items.push({
  name: 'Competitions',
  url: '/competitions',
  children: [{
    name: 'Competition List',
    url: '/competitions/competitions-list',
  },
  {
    name: 'Manage Competitions',
    url: '/competitions/manage-competitions',
  }
  ],
})

items.items.push({
  name: 'Shop',
  url: '/shop',
  //   children: [{
  //     name: 'Competition List',
  //     url: '/competitions/competitions-list',
  //   },
  //   {
  //     name: 'Manage Competitions',
  //     url: '/competitions/manage-competitions',
  //   }
  // ],
})

items.items.push({
  name: 'Promo Code',
  url: '/promocode',
})

items.items.push({
  name: 'Player Pack',
  url: '/PlayerPack',
})

items.items.push({
  name: 'Refferral Code',
  url: '/refferralcode',
  children: [{
    name: 'Refferral Amount',
    url: '/refferralcode',
  },
  {
    name: 'Refferral Report',
    url: '/refferralcode/refferral-report',
  }
  ],
})

export default items;
