import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, Row } from 'reactstrap';
import * as NC from "../../../helper/NetworkingConstants";
import WSManager from "../../../helper/WSManager";
import { notify } from 'react-notify-toast';
import { Base64 } from 'js-base64';
import Notification from 'react-notify-toast';
import logo from '../../../assets/img/brand/logo.png'
var md5 = require('md5');

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
    email: '',
    password: '',
    formErrors: {email: '', password: ''},
    emailValid: false,
    passwordValid: false,
    formValid: false
    }

  }
  
  handleUserInput (e) {
  const name = e.target.name;
  const value = e.target.value;
  this.setState({[name]: value}, 
        () => { this.validateField(name, value) });
    
  }


  validateField(fieldName, value) {

    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : ' is invalid';
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '': ' is too short';
        break;
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    emailValid: emailValid,
                    passwordValid: passwordValid
                  }, this.validateForm);
  }

  validateForm() { 
    this.setState({formValid: this.state.emailValid && this.state.passwordValid});
  }


  doLogin = () => { 
    WSManager.Rest(NC.baseURL + NC.DO_LOGIN,{email:this.state.email,password:md5(this.state.password)}).then((responseJson) => {
      if (responseJson.response_code === NC.successCode) {
        
        let sessionKey = responseJson.data.session_key;
        WSManager.setToken(sessionKey);

        //distributor	
        let role = responseJson.data.role;
        WSManager.setRole(role);
        WSManager.setCreatedBy(responseJson.data.createdby);
        WSManager.setLoggedInID(responseJson.data.admin_id);

        // WSManager.setKeyValueInLocal('ALLOW_COIN_MODULE', responseJson.data.module_setting.allow_coin);
        // WSManager.setKeyValueInLocal('ALLOW_PREDICTION_MODULE', responseJson.data.module_setting.allow_prediction);

        // WSManager.setKeyValueInLocal('ALLOW_OPEN_PREDICTOR', responseJson.data.module_setting.allow_open_predictor);

        WSManager.setKeyValueInLocal('LoadView', 'true')
        
        //start code international version
       // WSManager.setKeyValueInLocal('currency_code', responseJson.data.setting.currency_code);
 //       WSManager.setKeyValueInLocal('int_version', responseJson.data.setting.int_version);
        //end code international version

            //Start code for admin role
            WSManager.setKeyValueInLocal('module_access', responseJson.data.module_access)
            let redirectpath = (WSManager.getRole() > 1) ? 'distributors/detail/' + Base64.encode(responseJson.data.admin_id) : '';
             //Start code for admin role
            if (WSManager.getRole() > 1) {
              this.props.history.push(redirectpath);
            }
            else if(WSManager.getKeyValueInLocal('module_access').includes("user_management")) {
              this.props.history.push('/manage_user')
            } else {
              this.props.history.push('/welcome-admin')
            }
            //End code for admin role
           
      } else {
          notify.show("Api not configured", "error", 3000);
      }
  })

  }
 forgotPassword = () => {
   window.location.href = '/admin/#/forgot-password';
 }

  render() {
    return (
      <div className="nw-login">
        <div className="lg-head-str">	
        <div className="login-head">	
          <h4>{process.env.REACT_APP_ADMIN_NAME} Panel</h4>	
          <p className="xtext-muted">Let in to get going</p>	
        </div>	
      </div>
      <div className="app flex-row xalign-items-center">
        <Container>
          <Row className="justify-content-center login-form">
            <Col md="5">
              <div className="text-center mb-20 animate-left">
                  <img src={logo} className="footer-logo"/>
                </div>
              {/* <CardGroup> */}
                <Card className="xp-4 animate-right">
                  <CardBody>
                    <Form>
                      {/* <h3>Fantasy Panel</h3>
                      <p className="text-muted">Let in to get going</p> */}
                      
                      <Notification options={{ zIndex: 1060 }} />
                      <div className='formErrors'>
                      {Object.keys(this.state.formErrors).map((fieldName, i) => {
                        if(this.state.formErrors[fieldName].length > 0){
                          return (
                            
                            <div class="alert alert-danger fade show" role="alert" key={i}>{fieldName} {this.state.formErrors[fieldName]}.</div>
                          )        
                        } else {
                          return '';
                        }
                      })}
                     </div>
                      <InputGroup className="mb-3">
                        <Input type="text" placeholder="Email" autoComplete="email" name="email" value={this.state.username}
                              onChange={(event) => this.handleUserInput(event)}
                         />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <Input type="password" placeholder="Password" autoComplete="current-password"  name="password" value={this.state.password}
                           onChange={(event) => this.handleUserInput(event)}
                      />
                      </InputGroup>
                      <Row className="text-center">
                        <Col xs="12">
                          <Button className="btn-secondary px-4"  
                          disabled={!this.state.formValid} 
                          onClick={this.doLogin}>Login</Button>
                        </Col>
                      </Row>
                    </Form>
                    <Row className = "forgot-password">
      <Col xs = "12" >
      <a onClick={() => this.forgotPassword()} > Forgot Password? </a> 
      </Col> 
      </Row> 

                  </CardBody>
                </Card>
              
              {/* </CardGroup> */}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    );
  }
}

export default Login;
